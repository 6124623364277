<template>
    <TabView @tab-change="tabChange" class="AllUserInfoTabView" :activeIndex="activeIndex" >
        <TabPanel :header="DepartmentHeader" >
            <div class="p-d-flex" style="height: 100%;position: relative;">
                <ScrollPanel class="customScrollPanel" style="position: absolute; top: 0px; left: 0px; right: 0px; bottom: 0px;">
                    <AllDepartmentUserDataTable
                        :activeProp="activeIndex == 0"
                        :beforeDepartmentSelectKey="activeDepartmentId"
                        :beforeCompanySelectKey="activeCompanyId"
                        :beforeInitEdit="initEdit"
                    />
                </ScrollPanel>
            </div>
        </TabPanel>
        <TabPanel :header="DocumentHeader">
            <div class="p-d-flex" style="height: 100%;position: relative;">
                <ScrollPanel class="customScrollPanel" style="position: absolute; top: 0px; left: 0px; right: 0px; bottom: 0px;">
                    <InvitedFormDataTable :activeProp="activeIndex == 1" :gridData="invitedFormData" 
                        @fetchInvitedFormData="fetchInvitedFormData" :beforeSelectKey="beforeInvitedFormSelectKey" 
                        :beforeFocusKey="beforeInvitedFormFocusKey"
                        
                    />
                </ScrollPanel>
            </div>
        </TabPanel>
    </TabView>
    <div v-if="Loading" class="progressSpinnerContainer" style="z-index: 2;">
        <ProgressSpinner />
    </div>
</template>

<script>
import http from '../mixins/api';

import TabPanel from 'primevue/tabpanel'
import TabView from 'primevue/tabview'
import ScrollPanel from 'primevue/scrollpanel';

import InvitedFormDataTable from '@/components/InvitedFormDataTable'
import AllDepartmentUserDataTable from '@/components/AllDepartmentUserDataTable'
import ProgressSpinner from 'primevue/progressspinner';

export default {
    name: 'AllDepartmentUser',
    props: [''],
    components: {
        TabView,
        TabPanel,
        InvitedFormDataTable,
        AllDepartmentUserDataTable,
        ScrollPanel,
        ProgressSpinner,
    },
    data() {
        return {
            activeIndex: 0,
            DepartmentHeader: "",
            DocumentHeader: "",
            invitedFormData: [],
            activeDepartmentId: null,
            activeCompanyId: null,
            activeTemplateId: null,
            initEdit: null,
            beforeInvitedFormSelectKey: null,
            beforeInvitedFormFocusKey: null,
            Loading: true,
            // beforeSelectKey: null,
        }
    },
    created() {
        this.fetchData()
    },
    mounted() {
    },
    async beforeRouteEnter(route, redirect, next) {
        const query = route.query
        const activeIndex = query.activeIndex
        const activeDepartmentId = query.activeDepartmentId
        const activeCompanyId = query.activeCompanyId
        const initEdit = query.init_edit
        const activeTemplateId = query.activeTemplateId

        next(vm => vm.setData({activeIndex: activeIndex, activeDepartmentId: activeDepartmentId, activeTemplateId: activeTemplateId, activeCompanyId: activeCompanyId, initEdit: initEdit}))
    },
    computed: {
    },
    methods: {
        setData(params) {
            this.activeIndex = params.activeIndex ? Number(params.activeIndex) : 0
            this.activeDepartmentId = params.activeDepartmentId
            this.activeCompanyId = params.activeCompanyId
            this.initEdit = params.initEdit
            this.activeTemplateId = params.activeTemplateId
        },

        async fetchData() {
            this.Loading = true
            await this.$store.dispatch("userBranchInfo/setMemberList")
            this.$store.commit("userBranchInfo/setRegistrationTreeInfoData")
            const DepartmentList = this.$store.getters["userBranchInfo/getMemberList"]
            const userInfo = this.$store.getters["auth/getUserInfo"]
            const count = DepartmentList.filter(x => x.WorkUserId == userInfo._id).length
            const VisibleIdList = this.$store.getters["userBranchInfo/getVisibleIdList"]

            const res = await http.post("/form/GetInvitedFormAndUser")

            this.DepartmentHeader = this.$i18n.tc("allDepartmentUser.departmentHeader", {count: count}) ;
            this.DocumentHeader = this.$i18n.tc("allDepartmentUser.documentHeader", {length: res.invitedFormAndUserTree.length}) ;
            await(this.invitedFormData = res.invitedFormAndUserTree)

            if(this.activeIndex == 1 && this.activeTemplateId) {
                const activeData = res.invitedFormAndUserTree.find(x => x.data.TemplateId == this.activeTemplateId)
                if(activeData) {
                    this.beforeInvitedFormSelectKey = activeData.key;
                    this.beforeInvitedFormFocusKey = this.activeTemplateId
                    this.$toast.add({severity: 'success', summary: this.$i18n.tc("allDepartmentUser.focusedDocument"), life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
                } else {
                    this.$toast.add({severity:'error', summary: this.$i18n.tc("allDepartmentUser.dontShowDocument"), life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
                }
            }
            this.Loading = false
        },

        tabChange(e) {
            const activeIndex = e.index
            this.activeIndex = activeIndex

        },

        async fetchInvitedFormData() {
            const res = await http.post("/form/GetInvitedFormAndUser")
            this.DocumentHeader = this.$i18n.tc("allDepartmentUser.documentHeader", {length: res.invitedFormAndUserTree.length});
            this.invitedFormData = res.invitedFormAndUserTree
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.ghost {
    opacity: 0.5;
    background: #c8ebfb;
}
</style>
