<template>
    <div class="text-left" style="font-size: 12px;margin-top: 6px; margin-bottom: 6px;" v-html="$t('invitedFormDataTable.what')"> 
    </div>

    <TreeTable 
        :value="gridData"
        selectionMode="single"  :expandedKeys="expandedKeys"
        @node-select="onNodeSelect" @node-unselect="onNodeUnselect"
        ref="InvitedFormDataTreeTable"
        class="p-treetable-sm text-ellipsis-tree " >
        <Column  :header="$t('invitedFormDataTable.documentName')" :expander="true"  style="" >
            <template #body="slotProps">
                <template v-if="beforeFocusKey==slotProps.node.data.TemplateId">
                    <span
                        class="" style="background-color: yellow" 
                        v-tooltip.left="slotProps.node.data.DocumentName"
                    >
                        {{slotProps.node.data.DocumentName}}
                    </span>
                </template>
                <template v-else >
                    <span 
                        class=""
                        v-tooltip.left="slotProps.node.data.DocumentName"
                    >
                    {{slotProps.node.data.DocumentName}}
                    </span>
                </template>
                <template v-if="slotProps.node.data.IsInvitedUser">
                    <Button class="p-button p-button-warning p-button-text " style="    background: rgba(34, 197, 94, 0.16); border-color: transparent; color: #22C55E;" >
                        {{$t('invitedFormDataTable.isInvitedUser')}}
                    </Button>
                </template>
                <template v-else-if="slotProps.node.data.IsInvitedDepartment">
                    <Button 
                        class="p-button p-button-info p-button-text"
                        style="background: rgba(59, 130, 246, 0.16); border-color: transparent; color: #3B82F6;"
                        >
                        {{$t('invitedFormDataTable.isInvitedDepartment')}}
                    </Button>
                </template>


            </template>
        </Column>
        <Column :header="$t('invitedFormDataTable.departmentName')" style="width: 25%" >
            <template #body="slotProps">
                    <span  
                        class=""
                        v-tooltip.left="slotProps.node.data.IsInvitedUser ? '' : slotProps.node.data.DepartmentName "
                    >
                    {{slotProps.node.data.IsInvitedUser ? $t('invitedFormDataTable.isInvitedUserName'): slotProps.node.data.Email ? slotProps.node.data.Name : slotProps.node.data.DepartmentName}}
                    </span>
            </template>
        </Column>
        <Column :header="$t('invitedFormDataTable.companyName')" style="width: 25%"> 
            <template #body="slotProps">
                <span  
                    class=""
                    v-tooltip.left="slotProps.node.data.CompanyName"
                >
                    {{slotProps.node.data.IsInvitedUser ? "": slotProps.node.data.Email ? slotProps.node.data.DepartmentName : slotProps.node.data.CompanyName}}
                </span>

            </template>
        </Column>
        <Column  header="" headerStyle="width: 40px;" style="width: 40px;">
            <template #body="slotProps">
                <Button 
                    v-if="slotProps.node.data.IsInvitedDepartment || slotProps.node.data.IsInvitedUser"
                    icon="pi pi-times" 
                    class="p-button-rounded p-button-danger p-button-text" 
                    @click="showRemoveUserInTemplate($event, slotProps.node.data)"
                    v-tooltip="$t('invitedFormDataTable.showRemoveUserInTemplate')"
                    />
            </template>
        </Column>
        <Column field="Email" header="Email" style="width: 12%"></Column>
    </TreeTable>

    <Dialog v-model:visible="displayRemoveUserDialog">
        <div>
            {{$t('invitedFormDataTable.cancelShare')}}
        </div>
        <div class="p-d-flex p-jc-between">
            <Button 
                :label="$t('utils.cancel')" 
                class="p-button-raised p-button-secondary p-button-text" 
                @click="closeDialog"
            />
            <Button 
                :label="$t('utils.delete')" 
                class="p-button-raised p-button-text" 
                @click="closeDialog" />
        </div>
    </Dialog>


</template>

<script>
import OverlayPanel from 'primevue/overlaypanel';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import DataView from 'primevue/dataview';
import Chips from 'primevue/chips';
import http from '../mixins/api';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';

import DataTable from 'primevue/datatable'
import TreeTable from 'primevue/treetable'

import Column from 'primevue/column'
import Tooltip from 'primevue/tooltip'

export default {
    name: 'InvitedFormDataTable',
    props: ['activeProp', 'gridData', 'beforeSelectKey', 'beforeFocusKey'],
    emits: ['fetchInvitedFormData'],
    components: {
        Chips,
        InputText,
        DataView,
        OverlayPanel,
        Dialog,
        Button,
        DataTable,
        TreeTable,
        Column,
        TabView,
        TabPanel,
    },
    data() {
        return {
            selectedKey: {},
            expandedKeys: {},
            expandedRowGroups: null,
            displayRemoveUserDialog: false,
            displayUserInfo: null,
            displayDepartmentNameInfo: false,
            expandedRowGroups: null,
            displayUserInfoName: null,
            displayUserInfoDepartmentName: null,
            displayDepartmentName: null,
            // active: this.activeProp
        }
    },
    watch: {
        activeProp: async function(val) {
            if(val) {
            }
        },
        beforeSelectKey: function(value) {
            this.addExpandedKeys(value)
        },
        beforeFocusKey: function(value) {
        }
    },
    mounted() {
    },
    computed: {
    },
    methods: {
        onNodeSelect(node){
            this.addExpandedKeys(node.key)
        },
        addExpandedKeys(selectedKey) {
            this.selectedKey[selectedKey] = this.selectedKey[selectedKey] ? !this.selectedKey[selectedKey] : true
            this.expandedKeys = this.selectedKey 
        },
        onNodeUnselect(node){
        },
        expandNode(node) {
            if (node.children && node.children.length) {
                this.expandedKeys[node.key] = true;

                for (let child of node.children) {
                    this.expandNode(child);
                }
            }
        },
        showRemoveUserInTemplateClick(e, record) {
            this.displayUserInfo = record
            this.displayRemoveUserDialog = true
        },
        async showRemoveUserInTemplate(e, record) {
            const self = this;
            this.$confirm.require({
                message: self.$i18n.tc('invitedFormDataTable.cancelShare'),
                accept: async function() {
                    const res = await http.post("/form/DropInvitedDataFromTemplate", {TemplateId: record.TemplateId, IsInvitedDepartment: record.IsInvitedDepartment, Id: record.Id})
                    if(res.Success) {
                        self.$emit("fetchInvitedFormData")
                        self.$toast.add({severity:'success', summary: self.$i18n.tc("invitedFormDataTable.successRemoveInvitedUser"), life: self.$constants.successHelperLife, group: self.$constants.defaultToastGroup});
                    } else {
                        self.$toast.add({severity:'error', summary: self.$i18n.tc("utils.failed"), life: self.$constants.successHelperLife, group: self.$constants.defaultToastGroup});
                    }
                },
                reject: () => {
                }
            })
        },
        closeDialog(e) {
            this.displayUserInfoDialog = false
            this.displayRemoveUserDialog = false
            this.displayDepartmentNameInfoDialog = false
        }
    },
    directives: {
        'tooltip': Tooltip
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.ghost {
    opacity: 0.5;
    background: #c8ebfb;
}
</style>



