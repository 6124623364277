<template>
    <div v-if="!onlyOne" class="text-left" style="font-size: 12px;margin-top: 6px; margin-bottom: 6px;" v-html="$t('allDepartmentUserDataTable.what')"> </div>
    <div v-else class="text-left" style="font-size: 12px;margin-top: 6px; margin-bottom: 6px;" v-html="$t('allDepartmentUserDataTable.onlyOne')"> </div>
    <TreeTable 
        :value="Departments"
        selectionMode="single"  :expandedKeys="expandedKeys"
        @node-select="onNodeSelect" @node-unselect="onNodeUnselect"
        @node-expand="onRowExpand" @node-collapse="onRowCollapse"
        class="p-treetable-sm text-ellipsis-tree" >
        <template #empty>
            {{$t('allDepartmentUserDataTable.empty')}}
        </template>
        <Column  :header="$t('allDepartmentUserDataTable.header.name')" :expander="true" headerStyle="width: 25%">
            <template #body="slotProps">
                <template v-if="slotProps.node.data.IsCompany">
                    <span
                        class="" 
                        :class="[{'bg-yellow': beforeCompanySelectKey==slotProps.node.data._id}]"
                        v-tooltip.left="slotProps.node.data.Name"
                    >
                    {{
                        slotProps.node.data.Name ? slotProps.node.data.Name : $t('allDepartmentUserDataTable.header.blankCompanyName')
                    }}
                    </span>
                </template>
                <template v-else-if="slotProps.node.data.IsDepartment ">
                    <span
                        class="" 
                        :class="[{'bg-yellow': beforeDepartmentSelectKey==slotProps.node.data._id}, {'font-orange': slotProps.node.data.IsOPE}, {'isBelongTo':slotProps.node.data.IsBelong}]"
                        v-tooltip.left="slotProps.node.data.Name"
                    >
                    {{slotProps.node.data.DepartmentCode ? slotProps.node.data.DepartmentCode + " " : ""}}{{ slotProps.node.data.Name ? slotProps.node.data.Name : $t('allDepartmentUserDataTable.header.blankDepartmentName') }}
                    </span>
                </template>

                <template v-else>
                    <span class="" style="font-weight: bold;"
                        v-tooltip="slotProps.node.data.Name"
                    > {{slotProps.node.data.Name ? slotProps.node.data.Name : $t('allDepartmentUserDataTable.header.blankCreatorName')}}</span>
                </template>
            </template>
        </Column>
        <Column field="DepartmentName" :header="$t('allDepartmentUserDataTable.header.userDepartment')"  headerStyle="width: 25%" > </Column>
        <Column field="Head" header=""  headerStyle="width: 35%" >
            <template #body="slotProps">

                <Button 
                    v-if="slotProps.node.data.IsCompany"
                    class="p-button p-button-secondary p-button-text" 
                    style="background: rgba(100, 116, 139, 0.16); border-color: transparent; color: #64748B;"
                    :label="$t('allDepartmentUserDataTable.header.IsCompany')"
                    />
                <Button
                    v-if="slotProps.node.data.IsDepartment && slotProps.node.data.IsOPE"
                    class="p-button p-button-warning p-button-text "
                    style="  background-color: rgba(245, 158, 8, 0.16);"
                    :label="$t('allDepartmentUserDataTable.header.IsOPEDepartment')"
                    v-tooltip="$t('allDepartmentUserDataTable.header.InfoDepartment_OPE')"
                    />
                <Button
                    v-if="slotProps.node.data.IsDepartment && !slotProps.node.data.IsOPE"
                    class="p-button p-button-info p-button-text"
                    style="background: rgba(59, 130, 246, 0.16); border-color: transparent; color: #3B82F6;"
                    :label="$t('allDepartmentUserDataTable.header.IsDepartment')"
                    v-tooltip="$t('allDepartmentUserDataTable.header.InfoDepartment')"
                    />

                <Button
                    v-if="slotProps.node.data.IsCompany"
                    icon="pi pi-pencil"
                    class="p-button-rounded p-button-info p-button-text"
                    @click="showCompanyNameChangeDialog($event, slotProps.node.data)"
                    v-tooltip="$t('allDepartmentUserDataTable.header.changeCompanyNameTooltip')"
                    />
                <Button
                    v-if="slotProps.node.data.IsCompany"
                    icon="pi pi-plus"
                    class="p-button-rounded p-button-info p-button-text"
                    @click="onPlusDepartmentButtonClick($event, slotProps.node.data)"
                    v-tooltip="$t('allDepartmentUserDataTable.header.AddNewDepartmentTooltip')"
                    />
                <Button
                    v-if="slotProps.node.data.IsDepartment"
                    icon="pi pi-pencil"
                    class="p-button-rounded p-button-info p-button-text"
                    @click="showDepartmentNameChangeDialog($event, slotProps.node.data)"
                    v-tooltip="$t('allDepartmentUserDataTable.header.changeDepartmentNameTooltip')" />

                <Button
                    v-if="slotProps.node.data.IsDepartment"
                    icon="pi pi-user-plus"
                    class="p-button-rounded p-button-info p-button-text"
                    @click="onUserPlusDepartmentButtonClick($event, slotProps.node.data)"
                    v-tooltip="$t('allDepartmentUserDataTable.header.AddNewUserTooltip')"
                    />
                <Button 
                      v-if="slotProps.node.data.IsDepartment "
                      class="p-button-secondary p-button-text" 
                      v-bind:class="{'p-button-info': PaidDepartmentList ? PaidDepartmentList.map(x => x._id.toString()).includes(slotProps.node.data._id.toString()) : false}"
                      @click="showDepartmentPlanDialog($event, slotProps.node.data)"
                      :label="PaidDepartmentList && PaidDepartmentList.map(x => x._id.toString()).includes(slotProps.node.data._id.toString()) ? 'BUSINESS' : 'FREE' "
                      v-tooltip="$t('allDepartmentUserDataTable.header.changePricePlan')"
                      >
                </Button>
                <Button
                    v-if="slotProps.node.data.IsMember"
                    icon="pi pi-user-edit "
                    class="p-button-rounded p-button-info p-button-text"
                    @click="showUserInfoInDepartment($event, slotProps.node.data)"
                    v-tooltip="$t('allDepartmentUserDataTable.header.changeUserInfoTooltip')"
                    />
                <Button
                    v-if="slotProps.node.data.IsMember"
                    icon="pi pi-times"
                    class="p-button-rounded p-button-danger p-button-text"
                    @click="showRemoveUserInDepartment($event, slotProps.node.data)"
                    v-tooltip="$t('allDepartmentUserDataTable.header.deleteUserInfoTooltip')"
                    />
            </template>
        </Column>
        <Column field="Email" :header="$t('allDepartmentUserDataTable.header.userEmail')" headerStyle="width: 15%" ></Column>
    </TreeTable>

    <Dialog v-model:visible="displayCompanyNameInfoDialog"  style="width: 688px;">
        <template #header> 
            {{$t('allDepartmentUserDataTable.companyNameInfoDialog.header')}}
        </template>
        <div class="p-field p-grid">
            <label class="p-col-fixed" style="width:100px">{{$t('allDepartmentUserDataTable.companyNameInfoDialog.companyName')}}</label>
            <div class="p-col">
                <InputText type="text" v-model="displayCompanyName" style="width: 100%" @focus="$event.target.select()"  />
            </div>
        </div>

        <div class="p-grid p-jc-between">
            <div class="p-col-5">
            <Button 
                :label="$t('utils.cancel')" 
                class="p-button-raised p-button-secondary p-button-text" 
                @click="closeDialog"
            />
        </div>
        <div class="p-col-2"> </div>
        <div class="p-col-5 text-right">
            <Button 
                :label="$t('utils.change')" 
                class="p-button-raised p-button-text" 
                @click="editCompanyNameBtnClick" />
            </div>
        </div>
    </Dialog>

    <Dialog v-model:visible="displayDepartmentNameInfoDialog"  style="width: 688px;">
        <template #header> 
            {{$t('allDepartmentUserDataTable.departmentNameInfoDialog.header')}}
        </template>
        <div class="p-field p-grid">
            <label class="p-col-fixed" style="width:100px">{{$t('allDepartmentUserDataTable.departmentNameInfoDialog.departmentName')}}</label>
            <div class="p-col">
                <InputText type="text" v-model="displayDepartmentName" style="width: 100%" @focus="$event.target.select()"  />
            </div>
        </div>
        <div class="p-field p-grid">
            <label class="p-col-fixed" style="width:100px">{{$t('allDepartmentUserDataTable.departmentNameInfoDialog.departmentCode')}}</label>
            <div class="p-col">
                <InputText type="text" v-model="displayDepartmentCode" style="width: 40%" @focus="$event.target.select()"  />
            </div>
        </div>

        <div class="p-grid p-jc-between">
            <div class="p-col-5">
            <Button 
                :label="$t('utils.cancel')" 
                class="p-button-raised p-button-secondary p-button-text" 
                @click="closeDialog"
            />
        </div>
            <div class="p-col-2">
        </div>
            <div class="p-col-5 text-right">
            <Button 
                :label="$t('utils.change')" 
                class="p-button-raised p-button-text" 
                @click="editDepartmentNameBtnClick" />
        </div>
        </div>

    </Dialog>

    <Dialog v-model:visible="displayRemoveUserDialog">
        {{$t('allDepartmentUserDataTable.deleteUser')}}
        <div class="p-d-flex p-jc-between">
            <Button 
                :label="$t('utils.cancel')" 
                class="p-button-raised p-button-secondary p-button-text" 
                @click="closeDialog"
            />
            <Button 
                :label="$t('utils.delete')" 
                class="p-button-raised p-button-text" 
                @click="removeUserInDepartment" />
        </div>
    </Dialog>

    <Dialog :header="$t('allDepartmentUserDataTable.userInfoDialog.header')" v-model:visible="displayUserInfoDialog"  style="width: 688px;" >
        <div class="p-field p-grid">
            <label class="p-col-fixed" style="width:100px">{{$t('allDepartmentUserDataTable.userInfoDialog.name')}}</label>
            <div class="p-col">
                <InputText type="text" style="width: 100%" v-model="displayUserInfoName" @focus="$event.target.select()" />
                <span style="font-size: small;" > {{$t('allDepartmentUserDataTable.userInfoDialog.useCaseName')}} </span>
            </div>
        </div>
        <div class="p-field p-grid">
            <label  class="p-col-fixed" style="width:100px">{{$t('allDepartmentUserDataTable.userInfoDialog.department')}}</label>
            <div class="p-col">
                <InputText type="text" style="width: 100%"  v-model="displayUserInfoDepartmentName" @focus="$event.target.select()"  />
            </div>
        </div>
        <div class="p-field p-grid">
            <label  class="p-col-fixed" style="width:100px">Email:</label>
            <div class="p-col">
                <InputText type="text" style="width: 100%"  v-model:value="displayUserInfo.Email" disabled/>
                <span style="font-size: small;" > {{$t('allDepartmentUserDataTable.userInfoDialog.useCaseName')}} </span>
            </div>
        </div>
        <div class="p-d-flex p-jc-between">
            <Button 
                :label="$t('utils.cancel')" 
                class="p-button-raised p-button-secondary p-button-text" 
                @click="closeDialog"
            />
            <Button 
                :label="$t('utils.change')" 
                class="p-button-raised p-button-text" 
                @click="changeUserInfoInDepartmentBtnClick" />
        </div>
    </Dialog>

    <Dialog :header="$t('allDepartmentUserDataTable.editInfoDialog.header')" v-model:visible="displayInitEditInfoDialog"  style="width: 688px;" >
        <div class="p-field p-grid">
            <InlineMessage style=" width: 100%; justify-content: flex-start; "  severity="success">
                {{$t('allDepartmentUserDataTable.editInfoDialog.changeableAtAnyTime')}}
            </InlineMessage>
        </div>
        <div class="p-field p-grid">
            <label class="p-col-fixed" style="width:100px">{{$t('allDepartmentUserDataTable.editInfoDialog.companyName')}}</label>
            <div class="p-col">
                <InputText type="text" style="width: 100%" v-model="displayInitEditInfoCompanyName" @focus="$event.target.select()"  />
            </div>
        </div>
        <div class="p-field p-grid">
            <label  class="p-col-fixed" style="width:100px">{{$t('allDepartmentUserDataTable.editInfoDialog.departmentName')}}</label>
            <div class="p-col">
                <InputText type="text" style="width: 100%"  v-model="displayInitEditInfoDepartmentName" @focus="$event.target.select()"  />
            </div>
        </div>
        <div class="p-field p-grid">
            <label  class="p-col-fixed" style="width:100px">{{$t('allDepartmentUserDataTable.editInfoDialog.departmentCode')}}</label>
            <div class="p-col">
                <InputText type="text" style="width: 40%"  v-model="displayInitEditInfoDepartmentCode" @focus="$event.target.select()"  />
            </div>
        </div>
        <div class="p-d-flex p-jc-between">
            <Button 
                :label="$t('utils.cancel')" 
                class="p-button-raised p-button-secondary p-button-text" 
                @click="closeDialog"
            />
            <Button 
                :label="$t('utils.register')" 
                class="p-button-raised p-button-text" 
                @click="changeInitEditInfoInDepartmentBtnClick" />
        </div>
    </Dialog>
    <!-- <Dialog :closable="false" :visible="displayNextDialog" style="width: 100%; bottom: 0px;position: absolute;"> -->
    <!--     <div class="p-d-flex"> -->
    <!--         <Button  -->
    <!--              :label="$t('utils.next')  + ' >>'" -->
    <!--             class="p-col p-button-outlined "  -->
    <!--             @click="sentPush" /> -->
    <!--     </div> -->
    <!-- </Dialog> -->
    <!--  -->
</template>

<script>
import OverlayPanel from 'primevue/overlaypanel';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import DataView from 'primevue/dataview';
import Chips from 'primevue/chips';
import http from '../mixins/api';
import TreeTable from 'primevue/treetable'

import Column from 'primevue/column'
import Tooltip from 'primevue/tooltip'
import isMobile from '@/mixins/device'
import InlineMessage from 'primevue/inlinemessage';

export default {
    name: 'AllDepartmentUser',
    props: ['activeProp', 'beforeDepartmentSelectKey', 'beforeCompanySelectKey', 'beforeInitEdit'],
    components: {
        Chips,
        InputText,
        DataView,
        OverlayPanel,
        Dialog,
        Button,
        TreeTable,
        Column,
        isMobile,
        InlineMessage,
    },
    data() {
        return {
            departmentSelectKey: this.beforeDepartmentSelectKey,
            companySelectKey: this.beforeCompanySelectKey,
            initEdit: this.beforeInitEdit,
            selectedKey: {},
            expandedKeys: {},
            expandedRowGroups: null,
            displayUserInfoDialog: false,
            displayRemoveUserDialog: false,
            displayDepartmentNameInfoDialog: null,
            displayCompanyNameInfoDialog: null,
            displayUserInfo: null,
            displayDepartmentNameInfo: false,
            displayCompanyName: null,
            displayCompanyNameInfo: false,
            expandedRowGroups: null,
            displayUserInfoName: null,
            displayUserInfoDepartmentName: null,
            displayDepartmentName: null,
            displayDepartmentCode: null,
            displayInitEditInfoDialog: false,
            displayInitEditInfoCompanyName: null,
            displayInitEditInfoDepartmentName: null,
            displayInitEditInfoDepartmentCode: null,
            onlyOne: false,
            // displayNextDialog: true,

            // active: this.activeProp
        }
    },
    watch: {
        activeProp: async function(val) {
            if(val) {
                await this.fetchData()
            }
        },

        displayInitEditInfoDialog: function(value) {
            if(!this.initEdit) {
                if(isMobile.isMobile()) {
                    // this.displayNextDialog = true
                }
            }
            if(value == false) {
                if(localStorage.after_init_redirect_url) {
                    const after_init_redirect_url = localStorage.after_init_redirect_url
                    delete localStorage.after_init_redirect_url
                    location.href = after_init_redirect_url
                } else if(isMobile.isMobile()) {
                    this.$router.push({ name: "sent", params: {departmentId: this.$route.params.departmentId } });
                }
            }
        },
    },
    mounted() {
    },
    computed: {
        PaidDepartmentList: {
            get: function() {
                return this.$store.getters["userBranchInfo/getPaidDepartmentList"]
            }
        },
        Departments: {
            get: function() {
                const registrationTreeInfoData = this.$store.getters["userBranchInfo/getRegistrationTreeInfoData"]
                const ActiveCompanyObj = registrationTreeInfoData.find(x => x.data._id == this.companySelectKey)
                if(ActiveCompanyObj) {
                    const ActiveCompany = ActiveCompanyObj.data
                    const CompanyKey = ActiveCompanyObj.key
                    const DepartmentList = ActiveCompanyObj.children
                    const ActiveDepartment = DepartmentList.find(x => x.data._id == this.departmentSelectKey)
                    const DepartmentKey = ActiveDepartment ? ActiveDepartment.key : ""
                    this.addExpandedKeys(CompanyKey, true)
                    this.addExpandedKeys(DepartmentKey, true)
                    this.departmentSelectKey = null;
                    this.companySelectKey = null;
                    if(this.initEdit) {
                        this.displayInitEditInfoCompanyName = ActiveCompany.Name
                        this.displayInitEditInfoDepartmentName = ActiveDepartment ? ActiveDepartment.data.Name : ""
                        this.displayInitEditInfoDepartmentCode = ActiveDepartment ? ActiveDepartment.data.DepartmentCode : ""
                        this.displayInitEditInfoDialog = true
                        this.initEdit = false
                        this.$toast.add({severity:'info', summary: this.$i18n.tc("allDepartmentUserDataTable.pleaseUpdateInit"), life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
                    }
                }
                if(!registrationTreeInfoData.length) {
                    this.onlyOne = true
                }

                return registrationTreeInfoData;
            },
        }
    },
    created() {
        // this.fetchData()
    },
    methods: {
        async fetchData() {
            await this.$store.dispatch("userBranchInfo/setMemberList")
            this.$store.commit("userBranchInfo/setRegistrationTreeInfoData")
            
        },

        async addExpandedKeys(selectedKey, force=false) {
            if(force) {
                this.selectedKey[selectedKey] = true
            } else {
                this.selectedKey[selectedKey] = selectedKey in this.selectedKey ? !this.selectedKey[selectedKey] : true
            }
            // this.expandedKeys = this.selectedKey
            this.expandedKeys = {...this.selectedKey};

        },

        onRowExpand(node) {
            this.addExpandedKeys(node.key)
        },

        onRowCollapse(node) {
            this.addExpandedKeys(node.key)

        },

        onNodeSelect(node){
            this.addExpandedKeys(node.key)
        },

        onNodeUnselect(node){
        },

        expandNode(node) {
            if (node.children && node.children.length) {
                this.expandedKeys[node.key] = true;

                for (let child of node.children) {
                    this.expandNode(child);
                }
            }
        },

        showUserInfoInDepartment(e, record) {
            this.displayUserInfo = record
            this.displayUserInfoDialog = true
            this.displayUserInfoName = record.Name
            this.displayUserInfoDepartmentName = record.DepartmentName
        },

        async changeUserInfoInDepartmentBtnClick() {
            this.displayUserInfoDialog = false
            const record = this.displayUserInfo
            const res = await http.post("/user/UpdateDepartmentUserInfo", {updateData: {Name: this.displayUserInfoName, DepartmentName: this.displayUserInfoDepartmentName}, DepartmentUserId: record._id})
            if(!res.Success && res.Message) {
                this.$toast.add({severity:'error', summary: res.Message, life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
            }
            this.fetchData()
        },

        async changeInitEditInfoInDepartmentBtnClick() {
            const res = await http.post("/user/UpdateCompanyAndDepartmentInfo", {
                CompanyName: this.displayInitEditInfoCompanyName,
                DepartmentName: this.displayInitEditInfoDepartmentName,
                DepartmentCode: this.displayInitEditInfoDepartmentCode,
                DepartmentId: this.beforeDepartmentSelectKey,
                CompanyId: this.beforeCompanySelectKey,
            })

            if(res.Success) {
                this.$toast.add({severity:'success', summary: this.$i18n.tc("utils.registerAndUpdate"), life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
                await this.$store.dispatch("userBranchInfo/setMemberList")
                this.$store.commit("userBranchInfo/setWorkspace")
            } else {
                this.$toast.add({severity:'error', summary: this.$i18n.tc("utils.failed"), life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
            }
            this.closeDialog()
            if(isMobile.isMobile()) {
            } else {
                this.fetchData()
                // this.$router.push({ path: '/admin/top' });
            }
        },

        sentPush() {
            if(isMobile.isMobile()) {
                this.$router.push({ name: "sent", params: {departmentId: this.$route.params.departmentId } });
            }
        },

        showRemoveUserInDepartment(e, record) {
            this.displayUserInfo = record
            this.displayRemoveUserDialog = true
        },

        async removeUserInDepartment() {
            this.displayRemoveUserDialog = false
            const record = this.displayUserInfo
            const res = await http.post("/user/UpdateDepartmentUserInfo", {updateData: {IsDeleted: true}, DepartmentUserId: record._id})
            if(!res.Success && res.Message) {
                this.$toast.add({severity:'error', summary: res.Message, life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
            }
            this.fetchData()
        },

        showDepartmentNameChangeDialog(e, record) {
            this.displayDepartmentNameInfo = record
            this.displayDepartmentName = record.Name
            this.displayDepartmentCode = record.DepartmentCode
            this.displayDepartmentNameInfoDialog = true
        },

        async showDepartmentPlanDialog(e, record) {
            const departmentId = record._id

            const res_2 = await http.post('/customer/GetCustomerPortal', {departmentId: departmentId, returnUrl: location.href});
            if(res_2.Success) {
                window.open(res_2.portalSessionURL, '_blank');
            } else if(res_2.Message) {
                this.$toast.add({severity:'error', summary: res_2.Message, life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
            } else {
                let resolvedRoute = this.$router.resolve({
                    path: `/plans/info/${departmentId}`
                });
                window.open(resolvedRoute.href, '_blank');
            }
        },

        onPlusDepartmentButtonClick(e, record) {
            this.$router.push({ name: 'createDepartment', params: {companyId: record._id}, query: {c: record._id} });
        },

        onUserPlusDepartmentButtonClick(e, record) {
            this.$router.push({ name: 'inviteUser', params: {departmentId: record._id}, query: {d: record._id} });
        },

        showCompanyNameChangeDialog (e, record) {
            this.displayCompanyNameInfo = record
            this.displayCompanyName = record.Name
            this.displayCompanyNameInfoDialog = true
        },

        async editDepartmentNameBtnClick() {
            const displayDepartmentName = this.displayDepartmentName
            const displayDepartmentCode = this.displayDepartmentCode
            const res = await http.post("/user/UpdateDepartmentInfo", {updateData: {Name: displayDepartmentName, DepartmentCode: displayDepartmentCode}, DepartmentId: this.displayDepartmentNameInfo._id})
            if(res.Success) {
                this.$toast.add({severity:'success', summary: this.$i18n.tc("utils.update"), life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
            } else {
                this.$toast.add({severity:'error', summary: res.Message ? res.Message : this.$i18n.tc("allDepartmentUserDataTable.cantChangeDepartmentName"), life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
            }
            this.displayDepartmentNameInfoDialog = false
            this.fetchData()
        },

        async editCompanyNameBtnClick() {
            const displayCompanyName = this.displayCompanyName
            const res = await http.post("/user/UpdateCompanyInfo", {updateData: {Name: displayCompanyName}, CompanyId: this.displayCompanyNameInfo._id})
            if(res.Success) {
                this.$toast.add({severity:'success', summary: this.$i18n.tc("utils.update"), life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
            } else {
                this.$toast.add({severity:'error', summary: this.$i18n.tc("allDepartmentUserDataTable.cantChangeCompanyName"), life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
            }
            this.displayCompanyNameInfoDialog = false;
            this.fetchData()
        },

        closeDialog(e) {
            this.displayUserInfoDialog = false
            this.displayRemoveUserDialog = false
            this.displayDepartmentNameInfoDialog = false
            this.displayCompanyNameInfoDialog = false
            this.displayInitEditInfoDialog = false;

        },

    },
    directives: {
        'tooltip': Tooltip
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.ghost {
    opacity: 0.5;
    background: #c8ebfb;
}
.font-orange {
    color: #FF8C00
}
.bg-yellow {
    background-color: yellow
}
.font-red {
    color: red;
}
.isBelongTo:before {
    content: "";
    display: inline-block;
    background-color: #FF8C00;
    width: 6px; 
    height: 6px;
    border-radius: 50%;
    margin-right: 4px;
    position: relative;
    top: -2px;
}
</style>
